<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="toDetail('card')"
  >
    <div class="cover">
      <img src="../../../assets/image/idp.jpg" alt="cover" />
      <span class="year">{{ dataSource.planYear }}</span>
      <div class="status" v-show="listType == 'card'">
        <span class="green" v-if="dataSource.status == 1">
          {{ $t("idp.completed")
          }}<!-- 已完成 -->
        </span>
        <span class="red" v-else>
          {{ $t("idp.incomplete")
          }}<!-- 未完成 -->
        </span>
      </div>
    </div>
    <div class="detail">
      <h5>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.planName }}
            </div>
          </template>
          {{ dataSource.planName }}
        </a-popover>
      </h5>
      <template v-if="isReview">
        <div class="row" v-show="listType == 'card'">
          {{ $t("idp.rate_of_learning") }}：<!-- 学习进度 -->
          <span class="blue">{{ dataSource.progress }}</span
          >/{{ dataSource.details.length }}
          <span class="percentage">
            ({{ percentage(dataSource.progress, dataSource.details.length) }})
          </span>
        </div>
        <div class="row flex-start" v-show="listType == 'card'">
          <div class="img">
            <img :src="dataSource.portrait" alt="" />
          </div>
          <div>{{ dataSource.realName }}（{{ dataSource.account }}）</div>
        </div>
      </template>
      <template v-else>
        <div class="row" v-show="listType == 'card'">
          {{ $t("idp.rate_of_learning") }}：<!-- 学习进度 -->
          <span class="blue">{{ dataSource.progress }}</span
          >/{{ dataSource.details.length }}
          <span class="percentage">
            ({{ percentage(dataSource.progress, dataSource.details.length) }})
          </span>
        </div>
        <div class="row" v-show="listType == 'card'">
          {{ $t("idp.supervisor") }}：<!-- 监督人 -->
          {{ dataSource.collaboratorName }}
        </div>
        <div class="row space-between border" v-show="listType == 'card'">
          <div class="left blue" @click.stop="toEdit">
            {{ $t("idp.edit") }}
          </div>
          <div class="right red" @click.stop="toDelete">
            {{ $t("idp.delete") }}
          </div>
        </div>
      </template>
      <template v-if="!isReview">
        <div class="row mt12" v-show="listType == 'list'">
          {{ $t("idp.supervisor") }}：<!-- 监督人 -->
          {{ dataSource.collaboratorName }}
        </div>
      </template>
      <template v-else>
        <div class="row mt12" v-show="listType == 'list'">
          {{ $t("idp.student") }}：<!-- 学员 -->
          {{ dataSource.realName }}
        </div>
      </template>
      <div class="row mt12" v-show="listType == 'list'">
        {{ $t("idp.rate_of_learning") }}：<!-- 学习进度 -->
        <span class="blue">{{ dataSource.progress }}</span
        >/{{ dataSource.details.length }}
        <span class="percentage">
          ({{ percentage(dataSource.progress, dataSource.details.length) }})
        </span>
      </div>
      <div class="row mt12" v-show="listType == 'list'">
        {{ $t("idp.completion_status") }}：<!-- 完成状态 -->
        <span class="green" v-if="dataSource.status == 1">
          {{ $t("idp.completed")
          }}<!-- 已完成 -->
        </span>
        <span class="red" v-else>
          {{ $t("idp.incomplete")
          }}<!-- 未完成 -->
        </span>
      </div>
    </div>
    <template v-if="isReview">
      <div class="btns-list" v-show="listType == 'list'">
        <div class="btn" @click="toDetail('btn')">
          {{ $t("idp.view_details") }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="btns-list" v-show="listType == 'list'">
        <div class="btn blue" @click="toEdit">{{ $t("idp.edit") }}</div>
        <div class="btn red" @click="toDelete">{{ $t("idp.delete") }}</div>
        <div class="btn" @click="toDetail('btn')">
          {{ $t("idp.view_details") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { createVNode, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { idpDelete } from "@/api/idp";
export default {
  name: "IdpCard",
  props: {
    listType: {
      type: String,
      default: "card",
    },
    isReview: {
      type: Boolean,
      default: false,
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const router = useRouter();

    const toDetail = (type) => {
      if (type == "card") {
        if (props.listType == "card") {
          if (props.isReview) {
            emit("toResult");
          } else {
            router.push({
              path: "/mine/IDP/detail",
              query: {
                planId: props.dataSource.planId,
              },
            });
          }
        }
      } else {
        if (props.isReview) {
          emit("toResult");
        } else {
          router.push({
            path: "/mine/IDP/detail",
            query: {
              planId: props.dataSource.planId,
            },
          });
        }
      }
    };

    const toEdit = () => {
      router.push({
        path: "/mine/IDP/add",
        query: {
          planId: props.dataSource.planId,
        },
      });
    };

    const toDelete = () => {
      Modal.confirm({
        icon: createVNode(ExclamationCircleOutlined),
        content: proxy.$t("idp.are_you_sure_you_want_to_delete_name", {
          name: props.dataSource.planName,
        }),
        okText: proxy.$t("CM_Affirm"),
        onOk() {
          idpDelete(props.dataSource.planId).then((res) => {
            if (res.ret == 0) {
              emit("del");
            }
          });
        },
        cancelText: proxy.$t("CM_Cancel"),
      });
    };

    const percentage = (n1, n2) => {
      if (n2 === 0 || isNaN(n1) || isNaN(n2)) {
        return "0%";
      }
      return Math.round((n1 / n2) * 100) + "%";
    };

    return {
      toDetail,
      toEdit,
      toDelete,
      percentage,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
@font-face {
  font-family: youshe;
  src: url("../../../assets/fonts/YouSheBiaoTiHei.ttf");
}
.data-card {
  cursor: default;
  &.card {
    cursor: pointer;
    .cover {
      .status {
        background-color: rgba(255, 255, 255, 0.4);
        color: #fff;
        padding: 0 6px;
        font-size: 14px;
        line-height: 28px;
        border-radius: 4px;
        position: absolute;
        top: 12px;
        left: 12px;
      }
      .year {
        color: #fff;
        font-size: 42px;
        font-weight: bold;
        font-family: youshe !important;
        line-height: 42px;
        letter-spacing: 1px;
        position: absolute;
        top: 56px;
        left: 28px;
      }
      .bottom-mask {
        .mixinFlex(flex-start; center);
        font-size: 12px;
        .text {
          padding-left: 6px;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
    .row.border {
      border-top: 1px solid #f4f4f4;
      padding-top: 6px;
      & > div {
        cursor: pointer;
      }
    }
  }
  &.list {
    .cover {
      .mixinImgWrap(289px; 163px);
      position: relative;
      .year {
        color: #fff;
        font-size: 38px;
        font-weight: bold;
        font-family: youshe !important;
        line-height: 38px;
        letter-spacing: 1px;
        position: absolute;
        top: 56px;
        left: 25px;
      }
    }
  }
}
.btns-list {
  height: 130px;
  .mixinFlex(space-around; center);
  flex-direction: column;
  .btn {
    width: 120px;
    height: 32px;
    color: #666;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    padding: 2px 0;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #cacaca;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s;
    &.blue {
      color: #377dff;
    }
    &.red {
      color: #e8673e;
    }
    &:hover {
      &.blue {
        color: #fff;
        background-color: #377dff;
        border-color: #377dff;
      }
      &.red {
        color: #fff;
        background-color: #e8673e;
        border-color: #e8673e;
      }
    }
  }
}
.row {
  &.flex-start {
    .mixinEllipsis(20px);
    .img {
      .mixinImgWrap(20px; 20px);
      border-radius: 50%;
      margin-right: 8px;
    }
    .mixinFlex(flex-start; center);
  }
}
.percentage {
  padding-left: 4px;
}
</style>
